<header>
  <nav class="navbar shadow navbar-light bg-light">
    <a class="navbar-brand">
      <img src="https://res.cloudinary.com/davidcloudobi/image/upload/v1613554225/yus5bdftov3wp7zdet5z.png" (click)="sidenav.emit()" alt="Logo" class="logo mr-2">
      <span class="company">Olla Systems Limited</span>
    </a>
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </nav>
  <mat-menu #menu="matMenu">
    <button type="button" (click)="profile()" mat-menu-item>
      <img class="avatar" [src]="userImage" alt="">
      <span>Profile</span>
    </button>
    <button type="button" (click)="logout()" mat-menu-item>
      <mat-icon>login</mat-icon>
      <span>Sign Out</span>
    </button>
  </mat-menu>
</header>
