import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'olla-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  userImage = 'assets/placeholder.png';
  protected stop$ = new Subject<void>();
  @Output() sidenav = new EventEmitter<void>();

  constructor(private accountService: AccountService, private router: Router) {}

  ngOnInit(): void {
    this.accountService.user$.pipe(takeUntil(this.stop$)).subscribe(
      user => {
        if (user) {
          this.userImage = user.staffImageUrl || this.userImage;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  profile(): void {
    this.router.navigateByUrl('/profile');
  }

  logout(): void {
    this.accountService.logout();
  }
}
