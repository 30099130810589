import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './core/layout';
import { NoAuthGuard } from '@app/guards/no-auth.guard';
import { AuthGuard } from '@app/guards/auth.guard';
import { AuthLayoutComponent } from '@modules/auth/auth-layout/auth-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    loadChildren: () => import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        canMatch: [AuthGuard],
        loadChildren: () => import("@modules/home/home.module").then(m => m.HomeModule)
      },
      {
        path: 'sales',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@modules/sales/sales.module')
          .then(m => m.SalesModule)
      },
      {
        path: 'strategy',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@modules/strategy/strategy.module')
          .then(m => m.StrategyModule)
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@modules/settings/settings.module')
          .then(m => m.SettingsModule)
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@modules/profile/profile.module')
          .then(m => m.ProfileModule)
      },
      { path: '', redirectTo: '/home', pathMatch: 'full'  },
      { path: '**', redirectTo: '/home', pathMatch: 'full'  }
    ]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full'  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
