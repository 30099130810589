<div class="layout d-flex justify-content-center align-items-center">
  <mat-card class="login-card my-5">
    <mat-card-content class="login-card__content">
      <div class="d-flex justify-content-center mb-3">
        <img class="login-card__img" [src]="image" alt="OllaSystems">
      </div>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
