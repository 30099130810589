<div class="speed">
  <div class="user mt-1">
    <img [src]="userImage" alt="User" class="user__img">
    <p class="user__text">
      <span class="user__name">{{fullName}}</span>
      <span class="user__role">{{position}}</span>
    </p>
  </div>
  <nav class="sidebar-nav">
    <ul class="sidebar__list">
      <li class="sidebar__item">
        <a class="sidebar__link" [routerLink]="['/home']" routerLinkActive="sidebar__link--active">
          <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
          <span>Home</span>
        </a>
      </li>
      <li class="sidebar__item" routerLinkActive #opla="routerLinkActive">
        <a class="sidebar__link" [ngClass]="{'drop': activeDropdown == 'Sales', 'sidebar__link--active': opla.isActive}" (click)="changeDropdown('Sales')">
          <mat-icon aria-hidden="false" aria-label="Example home icon">request_quote</mat-icon>
          <span>Sales</span>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--down" aria-label="Example home icon">expand_more</mat-icon>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--right" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
        </a>
        <div class="nested__nav">
          <ul class="nested__list">
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/sales/opportunity']" routerLinkActive="nested__link--active" class="nested__link">Opportunities</a></li>
            <li class="nested__item"><a [routerLink]="['/sales/oem']" routerLinkActive="nested__link--active" class="nested__link">OEMs</a></li>
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/sales/client']" routerLinkActive="nested__link--active" class="nested__link">Clients</a></li>
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/sales/classification']" routerLinkActive="nested__link--active" class="nested__link">Classifications</a></li>
          </ul>
        </div>
      </li>
      <li class="sidebar__item" routerLinkActive #sla="routerLinkActive">
        <a class="sidebar__link" [ngClass]="{'drop': activeDropdown == 'Strategy', 'sidebar__link--active': sla.isActive}" (click)="changeDropdown('Strategy')">
          <mat-icon aria-hidden="false" aria-label="Example home icon">assessment</mat-icon>
          <span>Strategy</span>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--down" aria-label="Example home icon">expand_more</mat-icon>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--right" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
        </a>
        <div class="nested__nav">
          <ul class="nested__list">
            <li class="nested__item"><a [routerLink]="['/strategy/plans']" routerLinkActive="nested__link--active" class="nested__link">Plans</a></li>
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/strategy/kras']" routerLinkActive="nested__link--active" class="nested__link">KRAs</a></li>
            <!-- <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/strategy/appraisals']" routerLinkActive="nested__link--active" class="nested__link">Appraisals</a></li> -->
          </ul>
        </div>
      </li>
      <li class="sidebar__item" routerLinkActive #rla="routerLinkActive">
        <a class="sidebar__link" [ngClass]="{'drop': activeDropdown == 'Settings', 'sidebar__link--active': rla.isActive}" (click)="changeDropdown('Settings')">
          <mat-icon aria-hidden="false" aria-label="Example home icon">settings</mat-icon>
          <span>Settings</span>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--down" aria-label="Example home icon">expand_more</mat-icon>
          <mat-icon aria-hidden="false" class="sidebar__dropdown sidebar__dropdown--right" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
        </a>
        <div class="nested__nav">
          <ul class="nested__list">
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/settings/staff']" routerLinkActive="nested__link--active" class="nested__link">Staff</a></li>
            <li *ngIf="isAdmin" class="nested__item"><a [routerLink]="['/settings/department']" routerLinkActive="nested__link--active" class="nested__link">Department</a></li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</div>
