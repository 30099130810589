import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AccountService } from '@app/services/account.service';

export const NoAuthGuard: CanActivateFn = () => {
  const oauthService: AccountService = inject(AccountService);
  if (!oauthService.isLoggedIn()) {
    return true;
  }
  oauthService.goToDashboard();
  return false;
};
